<template>
    <form class="form" @submit="login">
        <div class="field">
            <label for="email">Email</label>
            <div class="control">
                <input type="text" name="email" id="email" v-model="email">
            </div>
        </div>
        <div class="field">
            <label for="password">Password</label>
            <div class="control">
                <input type="password" name="password" id="password" v-model="password">
            </div>
        </div>
        <div class="actions">
            <button type="submit">Submit</button>
        </div>
    </form>
</template>

<script>
    import store from '../store/store';

    export default {
        name: 'Login',
        data: function () {
            return {
                email: null,
                password: null,
                access_token: localStorage.getItem('access_token') ? localStorage.getItem('access_token') : null,
                expires_in: localStorage.getItem('expires_in') ? localStorage.getItem('expires_in') : null,
                refresh_token: localStorage.getItem('refresh_token') ? localStorage.getItem('refresh_token') : null,
                token_type: localStorage.getItem('token_type') ? localStorage.getItem('token_type') : null
            }
        },
        created: function () {

        },
        methods: {
            login: function (event) {
                event.preventDefault();
                const self = this;

                const formData = `userName=${this.email}&password=${this.password}&countryCode=44&bizType=smart_life&from=tuya`;

                this.$http.post(
                    'https://px1.tuyaeu.com/homeassistant/auth.do',
                    formData
                ).then(function(res) {
                    localStorage.setItem('access_token', res.data.access_token);
                    localStorage.setItem('expires_in', res.data.expires_in);
                    localStorage.setItem('refresh_token', res.data.refresh_token);
                    localStorage.setItem('token_type', res.data.token_type);

                    if (res.data.access_token) {
                        self.getDeviceList();
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            },
            getDeviceList: function () {
                const data = {
                    "header": {
                        "name": "Discovery",
                        "namespace": "discovery",
                        "payloadVersion": 1,
                    },
                    "payload": {
                        "accessToken": localStorage.getItem('access_token'),
                    },
                };
                this.$http.post(
                    'https://px1.tuyaeu.com/homeassistant/skill',
                    data
                ).then(function(res) {
                    if (res.data.header.code === "SUCCESS") {
                        store.state.devices = res.data.payload.devices;
                        localStorage.setItem('devices', JSON.stringify(res.data.payload.devices));
                        localStorage.setItem('es', JSON.stringify(res));
                    }
                })
                .catch(error => {
                    console.log(error)
                })
            }
        }
    }
</script>
