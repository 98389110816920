<template>
    <div class="device-card">
        <img :src="device.icon" alt="device.name">
        <h3>{{device.name}}</h3>
        <DeviceActions :deviceId="device.id" :deviceActionsData="device.data" />
    </div>
</template>

<script>
    import DeviceActions from './DeviceActions';

    export default {
        name: 'Device',
        props: {
            deviceData: {
                type: Object
            }
        },
        data: function () {
            return {
                device: this.deviceData
            }
        },
        created: function () {
            console.log(this.deviceData);
        },
        components: {
            DeviceActions
        }
    }
</script>

<style scoped>
    .device-card {
        width: 50%;
        padding: 0 15px;
    }

    img {
        max-width: 100%;
        border-radius: 10px;
    }
</style>