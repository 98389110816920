<template>
    <div class="custom-checkbox">
        <input type="checkbox" @change="this.change" v-bind:checked="this.checked">
    </div>
</template>

<script>
    export default {
        name: 'CustomCheckbox',
        props: {
            change: {
                type: Function,
                default: () => {}
            },
            checked: {
                type: Number,
                default: 0
            }
        },
        methods: {
            checkboxChange: function () {
                console.log('ok');
            }
        },
        created: function () {

        },
    }
</script>