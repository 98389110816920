<template>
    <div>
        <div class="device-state">
            <CustomCheckbox :checked="deviceIsOnline" :change="changeOnline" />
            <CustomCheckbox :change="launchRandomize" />
            <div id="hueRange">
                <input type="range" min="0" max="360" class="slider" @change="changeColor" value=""/>
            </div>
            <input type="range" min="11" max="100" id="brightnessRange" class="slider" @change="changeBrightness"/>
            <input type="range" min="1000" max="10000" id="colorTempRange" class="slider" @change="changeColorTemp"/>
        </div>
    </div>
</template>

<script>
    import CustomCheckbox from "./lib/CustomCheckbox";
    import {setColor, setDeviceState, setColorTemp, setBrightness} from "../helpers/actions";

    export default {
        name: 'DevicesAction',
        props: {
            deviceActionsData: {
                type: Object
            },
            deviceId: {
                type: String
            }
        },
        components: {
            CustomCheckbox
        },
        data: function () {
            return {
                deviceData: this.deviceActionsData,
                deviceIsOnline: this.deviceActionsData.online,
                timer: null,
                currentColor: {
                    brightness: 100,
                    hue: 100,
                    saturation: 1
                }
            }
        },
        created: function () {

        },
        methods: {
            changeOnline: function (event) {
                let state = event.target.checked ? 1 : 0;
                setDeviceState(this.deviceId, state);
            },
            launchRandomize: function (event) {
                if (event.target.checked) {
                    this.timer = setInterval(this.setRandomColor, 50);
                } else {
                    clearInterval(this.timer);
                }
            },
            setRandomColor: function () {
                const Bright = Math.floor(Math.random() * (100 - 0 + 1) + 0);
                const Hue = Math.floor(Math.random() * (360 - 0 + 1) + 0);
                const Saturation = Math.random() * (1 - 0 + 1) + 0;
                const newColor = {
                    brightness: Bright,
                    hue: Hue,
                    saturation: Saturation
                };

                console.log(newColor);
                setColor(this.deviceId, newColor);
            },
            changeColor: function (event) {
                this.currentColor.brightness = 0;
                this.currentColor.hue = event.target.value;

                setColor(this.deviceId, this.currentColor);
            },
            changeBrightness: function (event) {
                //this.currentColor.brightness = event.target.value;

                setBrightness(this.deviceId, event.target.value);
            },
            changeColorTemp: function (event) {
                //this.currentColor.saturation = event.target.value;

                setColorTemp(this.deviceId, event.target.value);
            }
        }
    }
</script>

<style scoped>
    #hueRange {
        background: linear-gradient(to right, red 0%, #ff0 17%, lime 33%, cyan 50%, blue 66%, #f0f 83%, red 100%);
        padding: 20px 0;
    }

    #hueRange input {
        display: block;
        width: 100%;
    }
</style>