import axios from 'axios';
import store from '../store/store';

export const setColor = (deviceId, color) => {
    const data = {
        "header": {
            "name": "colorSet",
            "namespace": "control",
            "payloadVersion": 1,
        },
        "payload": {
            "accessToken": localStorage.getItem('access_token'),
            "devId": deviceId
        },
    };

    data["payload"]["color"] = color;
    axios.post(
        'https://px1.tuyaeu.com/homeassistant/skill',
        data
    ).catch(error => {
        console.log(error)
    })
};

export const setBrightness = (deviceId, brightness) => {
    const data = {
        "header": {
            "name": "brightnessSet",
            "namespace": "control",
            "payloadVersion": 1,
        },
        "payload": {
            "accessToken": localStorage.getItem('access_token'),
            "devId": deviceId
        },
    };

    data["payload"]["value"] = brightness;
    axios.post(
        'https://px1.tuyaeu.com/homeassistant/skill',
        data
    ).catch(error => {
        console.log(error)
    })
};

export const setColorTemp = (deviceId, new_temperature) => {
    const data = {
        "header": {
            "name": "colorTemperatureSet",
            "namespace": "control",
            "payloadVersion": 1,
        },
        "payload": {
            "accessToken": localStorage.getItem('access_token'),
            "devId": deviceId
        },
    };

    data["payload"]["value"] = new_temperature;
    axios.post(
        'https://px1.tuyaeu.com/homeassistant/skill',
        data
    ).catch(error => {
        console.log(error)
    })
};

export const setDeviceState = (deviceId, state) => {
    const data = {
        "header": {
            "name": "turnOnOff",
            "namespace": "control",
            "payloadVersion": 1,
        },
        "payload": {
            "accessToken": localStorage.getItem('access_token'),
            "devId": deviceId
        },
    };

    data["payload"]["value"] = state;
    axios.post(
        'https://px1.tuyaeu.com/homeassistant/skill',
        data
    ).then(function() {
        let devices = store.state.devices;
        devices.map(function (device) {
            if (device.id === self.deviceId) {
                device.data.online = state;
                store.state.devices = devices;
                localStorage.setItem('devices', JSON.stringify(devices));
            }
        })
    }).catch(error => {
        console.log(error)
    })
};

export default {
    setColor,
    setDeviceState,
    setColorTemp
}