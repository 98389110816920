<template>
  <div id="app">
    <img alt="Foster logo" src="./assets/foster.png" id="logo">
    <Login v-if="!devicesData"/>
    <Devices v-if="devicesData" :devicesData="devicesData" />
  </div>
</template>

<script>
import Login from './components/Login.vue';
import Devices from './components/Devices.vue';
import store from './store/store';

export default {
  name: 'App',
  data: function () {
      return {

      }
  },
  computed: {
    devicesData () {
      return store.state.devices
    }
  },
  components: {
    Devices,
    Login
  }
}
</script>

<style>
  * {
    margin: 0;
    padding: 0;
  }
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  padding: 60px 0;
}
body {
  min-height: 100vh;
  background: #000;
}
  #logo {
    max-width: 230px;
  }
</style>
