<template>
    <div class="devices-container">
        <Device v-for="deviceData in devices" :key="deviceData.name" :deviceData="deviceData" />
    </div>
</template>

<script>
    import Device from './Device';

    export default {
        name: 'Devices',
        props: {
            devicesData: {
                type: Array
            }
        },
        data: function () {
            return {
                devices: this.devicesData
            }
        },
        created: function () {

        },
        components: {
            Device
        }
    }
</script>

<style scoped>
    .devices-container {
        display: flex;
        max-width: 600px;
        margin: 50px auto;
    }
</style>